<template>
  <cz-dialog
    v-on="$listeners"
    v-bind="$attrs"
    :title="
      $t('admin.manageAccountFeaturesDialog.title', [account.companyName])
    "
    :width="650"
    inset
    show-actions
    :loading="loading"
  >
    <div class="d-flex flex-column" style="gap: 5px">
      <template v-for="(item, index) of accountFeatures">
        <v-switch
          v-model="item.value"
          :key="`${item}_${index}`"
          :label="$t(`admin.accountFeatures.${item.name}`)"
          hide-details
          color="primary"
        />
      </template>
    </div>

    <template #actions>
      <v-spacer />
      <cz-button
        :title="$t('common.save')"
        color="primary"
        :min-width="100"
        large
        :disabled="!featuresChanged"
        @click="
          $emit('save', {
            account,
            accountFeatures
          })
        "
        :loading="loading"
      />
    </template>
  </cz-dialog>
</template>

<script>
import { CzDialog, CzButton } from '@/components';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
export default {
  name: 'ManageAccountFeaturesDialog',
  components: {
    CzDialog,
    CzButton
  },
  props: {
    account: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    allFeatures() {
      return ['order_creation', 'order_creation_with_items'];
    },
    featuresChanged() {
      console.log('check changed');
      return !isEqual(this.accountFeatures, this.accountFeaturesCopy);
    }
  },
  created() {
    this.initializeAccountFeatures();
    this.accountFeaturesCopy = cloneDeep(this.accountFeatures);
  },
  data() {
    return {
      accountFeaturesCopy: [],
      accountFeatures: []
    };
  },
  methods: {
    initializeAccountFeatures() {
      for (const item of this.allFeatures) {
        this.accountFeatures.push({
          name: item,
          value: this.account?.activeFeatures?.includes(item)
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
